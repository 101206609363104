import React, { useState } from 'react';
import axios from 'axios';

const CreateProjectModal = ({ onClose, onProjectCreated }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [manager, setManager] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [phone, setPhone] = useState('');

  const handleCreateProject = () => {
    const newProject = { name, address, manager, supervisor, phone };
    axios.post(`https://api.chconstruct.com/api/projects/new/project/`, newProject)
      .then(response => {
        onProjectCreated(response.data);
        onClose();
      })
      .catch(error => {
        console.error('There was an error creating the project!', error);
      });
  };

  return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-md w-1/3">
          <h3 className="text-xl font-bold mb-4">Create New Project</h3>
          <input 
            type="text" 
            placeholder="Project Name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input 
            type="text" 
            placeholder="Address" 
            value={address} 
            onChange={(e) => setAddress(e.target.value)} 
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input 
            type="text" 
            placeholder="Project Manager" 
            value={manager} 
            onChange={(e) => setManager(e.target.value)} 
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input 
            type="text" 
            placeholder="Supervisor" 
            value={supervisor} 
            onChange={(e) => setSupervisor(e.target.value)} 
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input 
            type="text" 
            placeholder="Contact Phone" 
            value={phone} 
            onChange={(e) => setPhone(e.target.value)} 
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <div className="flex justify-end mt-4">
            <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancel</button>
            <button onClick={handleCreateProject} className="bg-blue-500 text-white px-4 py-2 rounded">Create</button>
          </div>
        </div>
      </div>
  );
};

export default CreateProjectModal;
