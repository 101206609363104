import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material';
import CreateProjectModal from '../components/CreateProjectModal';
import TableOne from './Tables/TableOne';

const projectHeaders = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Project Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'manager', numeric: false, disablePadding: false, label: 'Project Manager' },
  { id: 'supervisor', numeric: false, disablePadding: false, label: 'Supervisor' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Contact Phone' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    axios.get('https://api.chconstruct.com/api/projects')
      .then(response => setProjects(response.data))
      .catch(error => console.error('There was an error fetching the projects!', error));
  }, []);

  const handleProjectCreated = (newProject) => {
    setProjects([...projects, newProject]);
  };

  const handleNewProjectClick = () => {
    setShowModal(true);
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Projects
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNewProjectClick}
          sx={{ mb: 2 }}
        >
          Create New Project
        </Button>
        {showModal && (
          <CreateProjectModal onClose={() => setShowModal(false)} onProjectCreated={handleProjectCreated} />
        )}
        <TableOne files={projects} headers={projectHeaders} onEdit={() => {}} />
      </Box>
    </Container>
  );
};

export default Projects;