import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material';

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    const validCredentials = [
      { username: 'CH', password: 'CH@2024' },
      { username: 'test', password: 'test' }
    ];

    const isValidUser = validCredentials.some(
      cred => cred.username === username && cred.password === password
    );

    if (isValidUser) {
      // Set a cookie to maintain the session for 3 hours
      Cookies.set('session', 'active', { expires: 0.125 }); // 3 hours

      if (onLoginSuccess) {
        onLoginSuccess();
      }
      navigate('/projects');
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="grey.100"
    >
      {/* Logo */}
      <Box display="flex" justifyContent="center" mb={4}>
        <img
          src="https://chconstruction-plans.s3.us-east-2.amazonaws.com/CH+Construction+logo+black+transparent.png"
          alt="CH Logo"
          style={{ width: '160px', height: 'auto' }}
        />
      </Box>

      <Container maxWidth="xs">
        <Box
          bgcolor="white"
          p={4}
          borderRadius={2}
          boxShadow={3}
          textAlign="center"
        >
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            onClick={handleLogin}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
