import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FileUploadModal = ({ isOpen, onClose, onUploadComplete, projectId, projectName }) => {
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [area, setArea] = useState('');
  const [type, setType] = useState('');
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!isOpen) {
      // Clear the form when the modal is closed
      setFile(null);
      setName('');
      setArea('');
      setType('');
      setMessage('');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file || !name || !area || !type) {
      setMessage('Please fill all fields');
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('area', area);
    formData.append('type', type);
    console.log(formData)
    try {
      const res = await axios.post(`https://api.chconstruct.com/api/projects/${projectId}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('File uploaded successfully');
      onUploadComplete(); // Refresh project details
      onClose();
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-1/3">
        <h3 className="text-xl font-bold mb-4">Upload a File</h3>
        <p className="mb-4">You are uploading a file to <strong>{projectName}</strong></p>
        <form onSubmit={handleUpload}>
          <input
            type="file"
            onChange={handleFileChange}
            className="mt-4 mb-2 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input
            type="text"
            placeholder="Area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <input
            type="text"
            placeholder="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="mb-2 p-2 border bg-white rounded w-full"
          />
          <div className="flex justify-end">
            <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancel</button>
            <button type="submit" disabled={uploading} className="bg-blue-500 text-white px-4 py-2 rounded">
              {uploading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </form>
        {message && <div className="mt-4">{message}</div>}
      </div>
    </div>
  );
};

export default FileUploadModal;
