import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TableTwo from './Tables/TableTwo';
import EditFileModal from './EditFileModal';
import FileUploadModal from './FileUploadModal';

const projectDetailsHeaders = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'area', numeric: false, disablePadding: false, label: 'Area' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'url', numeric: false, disablePadding: false, label: 'URL' },
  { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [editFile, setEditFile] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://api.chconstruct.com/api/projects/${projectId}`)
      .then(response => {
        setProject(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the project!', error);
      });
  }, [projectId]);

  const handleFileUpload = (fileData) => {
    axios.post(`https://api.chconstruct.com/api/projects/${projectId}/files`, fileData)
      .then(response => {
        setProject(response.data);
      })
      .catch(error => {
        console.error('There was an error uploading the file!', error);
      });
  };
 
  const handleEditFile = (file) => {
    setEditFile(file);
  };

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  const refreshProjectDetails = () => {
    axios.get(`https://api.chconstruct.com/api/projects/${projectId}`)
      .then(response => {
        setProject(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the project!', error);
      });
  };

  const handleUpdateFile = (updatedFile) => {
    axios.put(`https://api.chconstruct.com/api/projects/${projectId}/files/${updatedFile._id}`, updatedFile)
      .then(response => {
        setProject(response.data);
        setEditFile(null);
      })
      .catch(error => {
        console.error('There was an error updating the file!', error);
      });
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container w-full mx-auto p-4">
      <div className="bg-white w-full mx-auto">
        <div className="flex flex-wrap justify-between items-center mb-4">
          <button onClick={() => navigate('/projects')} className="bg-gray-100 text-gray-800 px-4 py-2 rounded mb-4 sm:mb-0">
            ←
          </button>
          <button onClick={() => setUploadModalOpen(true)} className="bg-blue-500 text-white px-4 py-2 ml-10 rounded mt-4 sm:mt-0">
            Upload
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4">{project.name}</h2>
        <p><strong>Address:</strong> {project.address}</p>
        <p><strong>Project Manager:</strong> {project.manager}</p>
        <p><strong>Supervisor:</strong> {project.supervisor}</p>
        <p><strong>Contact Phone:</strong> {project.phone}</p>

        <FileUploadModal 
          isOpen={uploadModalOpen} 
          onClose={() => setUploadModalOpen(false)} 
          onUploadComplete={refreshProjectDetails}
          projectName={project.name} 
          projectId={projectId} 
        />

        <h3 className="text-xl font-bold mt-6">Files</h3>
        {project.files.length > 0 ? (
          <TableTwo files={project.files} headers={projectDetailsHeaders} onEdit={handleEditFile} onDownload={handleDownload} />
        ) : (
          <p>No files uploaded yet.</p>
        )}

        {editFile && (
          <EditFileModal 
            isOpen={Boolean(editFile)} 
            file={editFile} 
            onUpdate={handleUpdateFile} 
            onClose={() => setEditFile(null)} 
            projectId={projectId} 
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
