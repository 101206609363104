import React, { useState } from 'react';
import axios from 'axios';

const EditFileModal = ({ file, onUpdate, onClose, projectId }) => {
  const [name, setName] = useState(file.name);
  const [area, setArea] = useState(file.area);
  const [type, setType] = useState(file.type);
  const [newFile, setNewFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewFile(e.target.files[0]);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('area', area);
    formData.append('type', type);
    if (newFile) {
      formData.append('file', newFile);
    }

    try {
      const res = await axios.put(`https://api.chconstruct.com/api/projects/${projectId}/files/${file._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('File updated successfully');
      onUpdate(res.data);
      onClose();
    } catch (error) {
      console.error('Error updating file:', error);
      setMessage('Error updating file');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-11/12 md:w-1/3">
        <h3 className="text-xl font-bold mb-4">Edit File</h3>
        <form onSubmit={handleUpdate}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-2 p-2 border bg-white border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            className="mb-2 p-2 border bg-white border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="mb-2 p-2 border bg-white border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <p>URL: <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Go to URL</a></p>
          <input
            type="file"
            onChange={handleFileChange}
            className="mt-4 mb-2 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          <div className="flex justify-end mt-4">
            <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600">Cancel</button>
            <button type="submit" disabled={uploading} className={`bg-blue-500 text-white px-4 py-2 rounded ${uploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}>
              {uploading ? 'Updating...' : 'Update'}
            </button>
          </div>
        </form>
        {message && <div className="mt-4">{message}</div>}
      </div>
    </div>
  );
};

export default EditFileModal;
